export class ValidatorsMessage {
  private _errorMessages = {
    required: 'O campo %s é obrigatório.',
    cpfInvalid: 'CPF é inválido.',
    noWhitespaceValidator: 'bla'
  };

  public get valiators(): object {
    return this._errorMessages;
  }
}
