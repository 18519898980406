<div class="card">
  <div class="card-body d-flex flex-column justify-content-center">
    <h5><strong> {{ label }} </strong></h5>
    
    <ngx-charts-bar-vertical-stacked
      [view]="view"
      [scheme]="colorScheme"
      [barPadding]="50"
      [results]="data"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [showGridLines]="false"
      [rotateXAxisTicks]="false"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [animations]="animations"
      (select)="onSelect($event)">
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
