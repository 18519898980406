export const URL_CONFIG = Object.freeze({
  url_autocomplete: 'autocomplete',
  url_perfis: 'perfil',
  url_usuario: 'usuario',
  url_restricoes: 'restricao',
  url_cep: 'cep',
  url_comissoes_avaliacao: 'ComissaoAvaliacao',
  url_avaliacao_desempenho: 'AvaliacaoDesempenho',
  url_comissoes_avaliacao_membros: 'ComissaoAvaliacao/membros/',
  url_ferias: 'ConcessaoFerias',
  url_relatorio: 'Relatorio',
  url_historico_resenha: 'historicoResenha',
  url_resenha: 'resenha',
  url_pgdi: 'pgdi',
  url_termo: 'termo',
  url_matricula: 'Matricula',
  url_parecer: 'Parecer',
  url_tipo_notificacao: 'TipoNotificacao',
  url_notificacao: 'Notificacao',
  url_configuracao_notificacao: 'TipoConfiguracaoNotificacao',
  url_secretaria_executiva: 'TipoSecretariaExecutiva',
  url_secretaria_geral: 'TipoSecretariaGeral',
  url_gerencia_executiva: 'TipoGerenciaExecutiva',
  url_gerencia_regional: 'TipoGerenciaRegional',
  url_cargo_comissionado: 'TipoCargoComissionadoProfissional',
  url_classificacao_funcional: 'TipoCodigoClassificacaoFuncionalProfissional',
  url_forma_admissao: 'TipoFormaAdmissaoProfissional',
  url_formacao_academica: 'TipoFormacaoProfissional',
  url_grau_instrucao: 'TipoInstrucaoProfissional',
  url_modalidade_ensino: 'TipoEnsinoProfissional',
  url_motivo_saida: 'TipoMotivoSaidaProfissional',
  url_situacao_funcional: 'TipoSituacaoFuncionalProfissional',
  url_tipo_aposentadoria: 'TipoAposentadoriaProfissional',
  url_importacao_profissionais: 'Importacao',
  url_exclusao_registro: 'ExclusaoRegistro',
  url_cargo: 'Cargo',
  url_regime_contratacao: 'RegimeContratacao'
});
