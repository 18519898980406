interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
  profiles?: string[];
}

export const MENUS_INFOBAR: MenuType[] = [
  {
    url: '',
    label: 'Home',
  },
  {
    url: 'area-usuario',
    label: 'Área do Usuário',
  },
];
