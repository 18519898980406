import { Routes } from '@angular/router';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';
import { SelecionarMatriculaComponent } from '@modules/auth/pages/selecionar-matricula/selecionar-matricula.component';

export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: null },
  },
  {
    path: 'selecionar-matricula',
    component: SelecionarMatriculaComponent
  },
  {
    path: '',
    component: LayoutAuthComponent,
    data: { breadcrumb: null },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        data: { breadcrumb: null },
      },
      // {
      //   path: 'avaliacao-desempenho',
      //   loadChildren: () =>
      //     import('./modules/avaliacao-desempenho/avaliacao-desempenho.module').then(
      //       (m) => m.AvaliacaoDesempenhoModule),
      //   data: { breadcrumb: 'Avaliação de Desempenho' },
      // },
      {
        path: 'configuracao',
        loadChildren: () =>
          import('./modules/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
        data: { breadcrumb: 'Configurações' },
      },
      {
        path: 'area-usuario',
        loadChildren: () =>
          import('./modules/area-usuario/area-usuario.module').then(
            (m) => m.AreaUsuarioModule
          ),
        data: { breadcrumb: 'Área do Usuário' },
      },
      // {
      //   path: 'ferias',
      //   loadChildren: () =>
      //     import('./modules/ferias/ferias.module').then(
      //       (m) => m.FeriasModule
      //     ),
      //   data: { breadcrumb: 'Férias' },
      // },
      {
        path: 'profissionais',
        loadChildren: () =>
          import('./modules/profissionais/profissionais.module').then(
            (m) => m.ProfissionaisModule
          ),
        data: { breadcrumb: 'Profissionais' },
      },
    ],
  },
];
