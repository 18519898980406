import { Component, Input } from '@angular/core';
import { TipoRelatorio } from '@core/enums/tipo-relatorio.enum';
import { BotaoTiposRelatorios } from '@core/interfaces/botao-tipos-relatorios.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-relatorios',
  templateUrl: './modal-relatorios.component.html',
  styleUrls: ['./modal-relatorios.component.scss']
})
export class ModalRelatoriosComponent {

  
  @Input() tituloModal: string = 'Por favor, selecione o formato desejado para o arquivo';
  @Input() botoesRelatorio: BotaoTiposRelatorios[] = 
  [ {nomeBotao: 'XLXS', exibirIcone: true, tipoRelatorio: TipoRelatorio.XLSX, iconeClass: 'fas fa-file-excel' },
    {nomeBotao: 'PDF', exibirIcone: true, tipoRelatorio: TipoRelatorio.PDF, iconeClass: 'fas fa-file-pdf' }
  ];

  public title: string;
  public message: string;

  constructor(public activeModal: NgbActiveModal) { }

  closeModal(tipoRelatorio?: TipoRelatorio) {
    this.activeModal.close(tipoRelatorio);
  }

}
