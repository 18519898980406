<div class="container" id="page-title-custom">
  <div class="row w-100 justify-content-between align-items-center">
    <div class="col-md-5 col-12">
      <h1>{{ pageTitle }}
        <button
        *ngIf="!expanded"
        type="button"
        class="btn rounded-pill btn-default btn-sm mr-2"
        (click)="expansive()">
        <em class="fas fa-chevron-right"></em>
      </button>
      <button
        *ngIf="expanded"
        type="button"
        class="btn rounded-pill btn-default btn-sm mr-2"
        (click)="expansive()">
        <em class="fas fa-chevron-down"></em>
      </button>
      </h1>
      <ng-content></ng-content>
    </div>
    <div class="col-md-5 col-12">
      <div class="d-flex align-items-center justify-content-end flex-wrap">
        <ng-container *ngFor="let item of menuItems; let i = index">
          <em class="fa fa-chevron-right fa-sm mx-2 text-gray"></em>
          <a class="breadcrumb-item" [routerLink]="item.url">
            {{ item.label }}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
