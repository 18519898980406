<div class="card mb-0">
    <div class="card-header">
      <div class="row">
        <h5 class="col mb-0 d-flex">Aviso</h5>
      </div>
    </div>
    <div class="card-body">
      <h5 class="text-center">
        Senha Incorreta
      </h5>
    </div>
  
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="d-flex justify-content-center">
                <button
                id="closeModal"
                type="submit"
                title="Voltar"
                (click)="closeModal()"
                class="btn btn-primary rounded-pill mb-4 ml-2">
                Voltar
                </button>
            </div>
        </div>
    </div>
  </div>