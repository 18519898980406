
export class ValidationMessages {
   /**
   * Mensagens de validação
   */
    messages = {
      login: {
        required: 'Login é obrigatório.',
        cpfInvalid: 'CPF é inválido.',
      },

      password: {
        required: 'Senha é obrigatória.',
      },

      senhaAtual: {
        required: 'A Senha Atual é obrigatória.',
      },

      novaSenha: {
        required: 'Senha é obrigatória.',
        passwordNotMatch: 'Senhas não se correspondem.',
        passwordInvalid:
          'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.',
      },

      novaSenhaRepeat: {
        required: 'Confirmar Senha é obrigatória.',
        passwordNotMatch: 'Senhas não se correspondem.',
        passwordInvalid:
          'A senha precisa ter pelo menos um número, um caractere e no mínimo 6 caracteres.',
      },

      name: {
        required: 'Nome é obrigatório.',
      },

      nome: {
        required: 'Nome é obrigatório.',
        maxLength: 'É permitido apenas 80 caracteres.',
      },

      sobrenome: {
        required: 'Sobrenome é obrigatório.',
      },

      cpf: {
        required: 'CPF é obrigatório.',
        cpfInvalid: 'CPF inválido.',
      },

      descricao: {
        required: 'Descrição é obrigatória.',
        maxLength: 'É permitido apenas 200 caracteres.',
      },

      email: {
        required: 'E-mail é obrigatório.',
        emailInvalid: 'E-mail é inválido.',
      },

      telefone: {
        required: 'Telefone é obrigatório.',
        phoneInvalid: 'Telefone é inválido.',
      },

      perfilId: {
        required: 'Perfil é obrigatório.',
      },

      perfils: {
        required: 'Perfil é obrigatório.',
      },
      parecerComissaoSobreResultado: {
        required: 'Campo é obrigatório.',
      },
      justificativaServidor: {
        required: 'Justificativa é obrigatório.',
      },
    };

  getMessages() {
    return this.messages;
  }
};
