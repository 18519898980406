<form [formGroup]="questionnaireForm">
  <div class="questionnaire" formArrayName="questionsArray">
    <ng-container
      *ngFor="
        let question of questionnaireForm?.get('questionsArray').controls;
        let i = index
      ">
      <div class="question" [formGroup]="question">
        <h6>{{ questiondescricao(i) }}
          <span *ngIf="isEditable" class="text-danger">*</span>
        </h6>
        <div formArrayName="opcoes">
          <div *ngFor="
              let option of question.get('opcoes').controls;
              let j = index
            ">
            <div class="option" [formGroup]="option">
              <input
                class="d-none"
                type="radio"
                [id]="'q' + i + 'o' + j"
                formControlName="selected"/>
              <div
                class="radio-custom"
                [ngClass]="{ checked: isChecked(option) }"
                (click)="markOption(question.get('opcoes'), option)">
              </div>
              <label [for]="'q' + i + 'o' + j">{{
                questionOptiondescricao(i, j)
              }}</label>
            </div>
          </div>
          <div class="infoTermo" *ngIf="isRouteTermo">
            <div class="col-12 col-md-auto">
              <label>Pontuação: {{ question.get('pontuacao').value }}</label>
              <label>Peso: {{ question.get('peso').value }}</label>
              <label>Total de Pontos: {{ question.get('totalPontos').value }}</label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
