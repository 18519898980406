import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {

  @Input() name: string = '';
  @Input() label: string = '';
  @Input() tooltipTitle = '';
  @Input() required: boolean = false;
  @Input() validateMessage: any = null;
  @Input() isInvalid = false;

  constructor() { }

  ngOnInit(): void {
  }

}
