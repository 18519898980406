import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBase } from 'src/app/shared/utils/form-base';

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent extends FormBase implements OnInit {

  @Input() entity: any;
  @Input() canSubmit: boolean = true;
  @Output() onSubmit = new EventEmitter<boolean>();
  @Input() lastUser: boolean = false; 

  constructor(public router: Router, public activedRoute: ActivatedRoute) {
    super(router, activedRoute)
  }

  ngOnInit(): void {
  }
  get ultimaAtualizacao() {
    return this.entity?.dataAlteracao || this.entity?.dataCriacao
  }
  submit() {
    this.onSubmit.emit();
  }

  get ultimoUsuario() {
    return this.entity?.ultimoUsuario;
  }

  get formValue() {
    return this.form.getRawValue();
  }

  get pathBack() {
    const splitedPath = this.router.url.split('/');
    const indexAtRemove = this.isView || this.isEdit ? -2 : -1; // -2 => /visualizar/123123213123;  -1 => /cadastrar
    return splitedPath.slice(0, indexAtRemove).join('/')
  }







}
