<div class="card">
    <div class="card-body d-flex flex-column justify-content-center">
        <h5 class="mb-3"><strong> {{ label }} </strong></h5>

        <div #containerRef>
            <ngx-charts-bar-vertical-stacked [id]="id" [view]="view" [scheme]="colorScheme" [barPadding]="50" [results]="data"
                [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel" [showGridLines]="showGridLines" [rotateXAxisTicks]="false"
                [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [animations]="animations"
                [legendTitle]="legendTitle" [legendPosition]="legendPosition" [barPadding]="barPadding"
                [showDataLabel]="showDataLabel">
            </ngx-charts-bar-vertical-stacked>
        </div>
    </div>
</div>
