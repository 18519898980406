import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-redigite-senha-incorreta',
  templateUrl: './modal-redigite-senha-incorreta.component.html',
  styleUrls: ['./modal-redigite-senha-incorreta.component.scss']
})
export class ModalRedigiteSenhaIncorretaComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  public closeModal() {
    this.activeModal.close();
  }

}
