import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-bar-chart-linea-status',
  templateUrl: './custom-bar-chart-linea-status.component.html',
  styleUrls: ['./custom-bar-chart-linea-status.component.scss']
})
export class CustomBarChartLineaStatusComponent implements OnInit {

  @Input() data: any[];
  @Input() color: string = '';
  @Input() description: string;
  @Input() totalValue: string;
  @Input() filter: string
  @Input() text: string;
  @Input() customSize: boolean = true;

  ngOnInit() {
    this.calculateTotal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.filter) {
      this.calculateTotal();
    }
  }

  private calculateTotal() {
    if (this.data && this.filter) {
      const filteredData = this.data.find(item => item.name === this.filter);
      this.totalValue = filteredData ? filteredData.value.toString() : '0';
    } else {
      this.totalValue = '0';
    }
  }
}
