export class NotificacaoHomeCardsDto {
  public lida: number;
  public naoLida: number;
  public arquivada: number;

  constructor(object?: any) {
    if (object) {
      Object.assign(this, object);
    }
  }
}
