<div class="card">
  <div class="card-body">
    <div class="row">
      <div #ref class="col-7 d-flex flex-column justify-content-center">
        <ng-content select="[content]" class="d-flex"></ng-content>
      </div>
      <div [ngClass]="ref.children.length == 0 ? 'col-12' : 'col-5'">
        <div #chart>
          <ngx-charts-advanced-pie-chart
            [view]="view"
            [results]="data"
            [label]="label"
            [animations]="true"
            [scheme]="colorScheme"
            [percentageFormatting]="percentFormat"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          >
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end" *ngIf="buttons">
      <ng-content select="[buttons]"></ng-content>
      <!-- <button
        type="submit"
        title="Pesquisar"
        class="btn btn-circle btn-xl rounded-pill btn-primary float-right mb-2 col-md-auto"
        (click)="onClick($event.target)"
      >
        <em class="fa fa-search"></em>
      </button> -->
      <div buttons>
        <button type="submit"
          (click)="onClick($event.target)"
          title="Ver Detalhes"
          class="btn btn-circle btn-xl rounded-pill btn-primary float-right mb-2 mr-2 col-md-auto">
            Ver Detalhes
        </button>
      </div>
    </div>
  </div>
</div>
