import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Route, Routes } from '@angular/router';
import { ExclusaoRegistroDto } from '@core/dto/ExclusaoRegistro/exclusao-registro-dto';
import { FormatoArquivo } from '@core/enums/formato-arquivo.enum';
import { ErrorMessage } from '@core/models/error-message.model';
import { ValidateMessage } from '@core/models/validate-message.model';
import { ArquivoService } from '@core/services/arquivo.service';
import { environment } from '@env/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { GenericValidatorForm } from './generic-validator-form';
import { SweetalertCustom } from './sweetalert-custom';

const NAME_PROJECT = environment.namespaceProject;

export class Util {
  /**
   * Função para setar os errors da requisição conforme de acordo com os seus tipos
   * @param errorMessage Objeto utilizado para incluir os erros
   * @param error Objeto que vem o HttpErroResponse
   * @param typeErr String para setar qual o tipo de error ex: danger, success, warning, info
   * @param errorCustom Boolean para caso queira inserir uma mensagem sem ser pelo tipo do erro da requisição
   */
  static setErrorMessage(
    errorMessage: ErrorMessage,
    error,
    typeErr = 'danger',
    errorCustom = false
  ) {
    if (errorCustom) {
      errorMessage.errorType = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 400) {
      errorMessage.errorType = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 403) {
      errorMessage.errorType = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push('Usuário não tem permissão de acesso!');
    }

    if ((!errorCustom && error.status === 0) || error.status === 404) {
      errorMessage.errorType = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push(
        'Oopss ocorreu um erro ao processar o seu pedido, não conseguimos conectar com os nossos serviços, por favor tente novamente!'
      );
    }

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, 10000);
  }

  static setErrorMessageCustom(
    errorMessage: ErrorMessage,
    error: any,
    typeErr = 'danger',
    timeoutClearMessage = 30000
  ) {
    errorMessage.errorType = typeErr;
    errorMessage.existError = true;
    errorMessage.errorList = this.setErrorListCustom(error);

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, timeoutClearMessage);
  }

  /**
   * Função para Limpar Objeto utilizado para incluir os erros
   * @param errorMessage Objeto utilizado para incluir os erros
   */
  static clearErrorMessage(errorMessage: ErrorMessage) {
    errorMessage.errorType = '';
    errorMessage.existError = false;
    errorMessage.errorList = [];
  }

  /**
   * Função utilizada para setar os errors na lista de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorList(error) {
    let list = [];
    Array.isArray(error.error.errors)
      ? (list = error.error.errors)
      : list.push(error.error.errors);
    return list;
  }

  /**
   * Função utilizada para setar os errors na lista custumizada de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorListCustom(error) {
    let list = [];
    Array.isArray(error) ? (list = error) : list.push(error);
    return list;
  }

  /**
   * Função para alterar o valor do botão de Buscar
   * @param buttonSubmitConfig Objeto para configuração do botão
   * @param isResp Controle a submissão do botão
   */
  static setBtnFilterReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Buscando' : 'Buscar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Salvando' : 'Salvar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReqCustom(
    buttonSubmitConfig?,
    isResp = false,
    desc1 = '',
    desc2 = ''
  ) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? desc2 : desc1;
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }
  /**
   * Metodo para pegar o nome para a tela (cadastrar/editar)
   * @param id Identificador
   * @param detalhes Bolean para informar se a tela é de detalhes
   */
  static getScreenName(id?: string, detalhes = false) {
    return !id || !id.trim()
      ? 'ADICIONAR'
      : id && !detalhes
        ? 'EDITAR'
        : 'VISUALIZAR';
  }

  /**
   * Função para setar a classe de errro no campo
   * @param formGroup FormGroup do parametro
   * @param messageDisplay Mensagem a ser exibida
   * @param field Campo que receberá a mensagem de validação
   */
  static setErrorsValidate(
    formGroup: FormGroup,
    messageDisplay,
    field: string
  ) {
    if (messageDisplay[field]) {
      return 'is-invalid';
    }
  }

  /**
   * Função para preencher os valores pelo ID
   * @param resp variavel que vem os dados da requisição
   * @param formGroup variavel que traz o form group
   */
  static patchValueForm(obj: any, formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      if (obj[key] || typeof obj[key] === 'boolean') {
        try {
          formGroup.controls[key].patchValue(obj[key]);
        } catch (error) {}
      }
    });
  }

  /**
   * Adiciona na URL o parametro de "Ativo" com o valor "true"
   */
  static createFilterStatusActive() {
    const params: URLSearchParams = new URLSearchParams();
    params.append('ativo', 'true');

    return params;
  }

  /**
   * Retorna as URL Search Params utilizadas nas requisições
   */
  static createFilter() {
    const params: URLSearchParams = new URLSearchParams();
    return params;
  }

  /**
   * Remove os acentos da string
   * @param str string para ser removida os acentos
   */
  static removeAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Limpa todos os campo do form
   * @param formGroup O form a ser limpo os campos
   */
  static clearFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const control = formGroup.get(campo);
      if (control instanceof FormGroup || control instanceof FormControl) {
        if (control instanceof FormGroup) {
          this.clearFields(control);
        } else {
          if (Array.isArray(control.value)) {
            control.setValue([]);
          } else {
            control.setValue('');
          }
        }
      } else if (control instanceof FormArray) {
        control.controls.splice(0);
        control.updateValueAndValidity();
      }
    });
    formGroup.updateValueAndValidity();
  }

  /**
   * Cria as query params do filtro de busca
   * @param param Objero a ser convertido em parametros
   */
  static getQueryParams(param: object) {
    const params: URLSearchParams = Util.createFilter();
    Object.keys(param).forEach((campo) => {
      const item = param[campo];
      if (Array.isArray(item)) {
        item.forEach((itemOfArray) => {
          params.append(campo, itemOfArray);
        });
      } else if (item) {
        params.append(campo, item);
      }
    });

    return params;
  }

  /**
   * Obtem os campos invalidos do form
   * @param form Form a ser verificado
   */
  static catchFieldsInvalids(form: FormGroup | FormArray): string[] {
    const invalidControls: string[] = [];

    const recursiveFunc = (formGP: FormGroup | FormArray) => {
      Object.keys(formGP.controls).forEach((field) => {
        const control = formGP.get(field);
        if (control instanceof FormGroup || control instanceof FormArray) {
          recursiveFunc(control);
        } else if (control.invalid) {
          invalidControls.push(field);
        }
      });
    };
    recursiveFunc(form);
    return invalidControls;
  }

  /**
   * * Abre o modal no tamanho
   * @param modalService Serviço do modal
   * @param component O componente a ser aberto
   * @param size O tamanho do modal (lg, xl, sm) / Valor defaul md
   */
  static openModal(
    modalService: NgbModal,
    component: any,
    size: string = 'md',
    options: NgbModalOptions = {}
  ) {
    const modalRef = modalService.open(component, {
      backdrop: 'static',
      size,
      keyboard: false,
      windowClass: 'modal-custom-' + size,
      ...options,
    });

    return modalRef;
  }

  /**
   * Convert o arquivo de base64 em BlobData (funciona bem apenas para formato PDF)
   * @param base64Data Arquivo em Base64
   * @param contentType Tipo do content type
   * @param sliceSize Tamanhp do arquivo
   */
  static convertBase64ToBlobData(
    base64Data: string,
    contentType: string = 'image/png',
    sliceSize = 512
  ) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Realiza o download do arquivo
   * @param file Arquivo para ser baixado
   */
  static downloadFile(file: any) {
    const blobData = Util.convertBase64ToBlobData(file.base64);

    if (window?.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobData, file.filename);
    } else {
      // chrome
      const blob = new Blob([blobData], { type: file.extension });
      const url = URL.createObjectURL(blob);
      const ext =
        file.extension.split('/').length > 0
          ? file.extension.split('/')[1]
          : '';
      if (
        ext === 'pdf' ||
        ext === 'PDF' ||
        ext === 'png' ||
        ext === 'PNG' ||
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'JPEG'
      ) {
        window.open(url, '_blank');
        return;
      }

      const link = document.createElement('a');
      link.href = url;
      link.download = file.filename;
      link.click();
    }
  }

  static downloadFileXLS(base64: any, fileName: string): void {
    const downlodedFile = new Blob([base64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  static distinct(myArr, prop): any[] {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  static getListYear(): any[] {
    const yearToday = new Date().getFullYear();
    const range = [];
    range.push(yearToday);
    for (let i = 1; i < 12; i++) {
      range.push(yearToday - i);
    }
    return range;
  }

  static getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
  // 2022-03-16T17:39:34.185889
  static formatDateHours(date?: string): string {
    if (date) {
      const ano = date.substring(0, 4);
      const mes = date.substring(5, 7);
      const dia = date.substring(8, 10);

      const newDate = new Date(date);

      const horas = newDate.getHours();
      const minutos = newDate.getMinutes();

      return dia + '/' + mes + '/' + ano + ' às ' + horas + ':' + minutos;
    }
  }

  static formatAnotherDateHours(date: string) {
    return moment(date).format('DD [de] MMMM [de] YYYY [às] HH:mm:ss');
  }

  static formatAnotherDate(date: string) {
    return moment(date).format('DD [de] MMMM [de] YYYY');
  }

  static formatAnotherDateWithFormat(date: string, format: string) {
    return moment(date, format).format('DD [de] MMMM [de] YYYY');
  }

  static formatDateHoursMinutes(date: string) {
    return moment(date).format('DD/MM/YYYY [às] HH:mm');
  }

  /**
   * Função que retorna um string com o formato 'YYYY-MM-DD'
   * @param date data a ser convertida para o formato do form
   */

  static formatDateToInputFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  static formatAnotherDateFormatForInput(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  /**
   * Função que converte uma string com o formato 'YYYY-MM-DD' em uma data
   * @param date data em string a ser convertida para o formato de data
   */

  static formatStringToDate(date: string) {
    if (date && date.trim() !== '') {
      return new Date(
          Number(date.slice(0, 4)),
          Number(date.slice(5, 7)) - 1,
          Number(date.slice(8, 10))
      );
    }
    return null;
  }

  static getAnos(): Array<any> {
    const anosList = [];

    for (let ano = 2012; ano <= 2012 + 11; ano++) {
      anosList.push({
        name: ano,
        value: ano,
      });
    }

    return anosList;
  }

  /**
   * Função que converte File em Base64
   * @param file objeto do tipo File a ser convertido
   */
  static async fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve((reader.result as string).split(';base64,').pop());
    });
  }

  static base64ToArrayBuffer(base64) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return byteArrays;
  }

  static objectToFile(obj) {
    return new File([obj.binary], obj.name, {
      type: obj.type,
      lastModified: obj.lastModify,
    });
  }

  /**
   * Ordena os cards de submenu por ordem alfabética
   * @param rotas lista de rotas para ser ordenada
   */
  static sortRoutes(rotas: Routes) {
    return rotas?.sort((a: Route, b: Route) => {
      if (a.path > b.path) {
        return 1;
      } else if (a.path < b.path) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  static validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        this.validateAllFormFields(control);
      }
    });
    return formGroup.valid;
  }

  /** Gerar mensagens de erro para um formulário específico*/

  static getValidadeMessage(form, messages) {
    const validateMessage = new ValidateMessage();

    validateMessage.validationMessages = { ...messages }

    validateMessage.genericValidator = new GenericValidatorForm(
      validateMessage.validationMessages
    );

    validateMessage.messageDisplay = validateMessage.genericValidator.processMessages(form);
    return validateMessage;
  }

  static getMatriculas() {
    const matriculasProfissionalLogado = localStorage.getItem(`${NAME_PROJECT}.profissionalMatriculas`);
    return matriculasProfissionalLogado ? JSON.parse(atob(matriculasProfissionalLogado)) : null;
  }

  static getPerfilMatriculaSession() {
    const perfilMatricula = localStorage.getItem(`${NAME_PROJECT}.perfilAcesso`);
    return perfilMatricula ? JSON.parse(atob(perfilMatricula)) : null;
  }

  /**
   * Convert o arquivo de base64 em BlobData (funciona para os formatos pdf, jpg, jpg e png)
   * @param base64 Arquivo em Base64
   * @param extensao Formato do arquivo
   */
  static base64toBlob(base64: string, extensao: string): Blob {
    let type = '';
    switch (extensao.toLowerCase()) {
      case FormatoArquivo.PDF:
        type = 'application/pdf';
        break;
      case FormatoArquivo.JPG:
        type = 'image/jpg';
        break;
      case FormatoArquivo.JPEG:
        type = 'image/jpeg';
        break;
      case FormatoArquivo.PNG:
        type = 'image/png';
        break;
    }
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type });
  }

  static formatDate(date: Date | string) {
    return new Date(date).toISOString();
  }

  static validarData(dataString: string): boolean {
    const data = new Date(dataString);
    return !isNaN(data.getTime()) && data.toISOString() === dataString;
  }

  static montarParametrosDeVerificarRelacionamento(id: string, schemaNome: string, tabelaNome): URLSearchParams {
    const params: URLSearchParams = Util.createFilter();
    params.append('schemaNome', schemaNome);
    params.append('tabelaNome', tabelaNome);
    params.append('id', id);

    return params;
  }

  static exibirTabelasRelacionadas(item: any) {
    SweetalertCustom.ShowAlertConfirmExclusaoByRelacionamento(
      item.tabelasRelacionadas
    );
  }

  static setarTabelasRelacionadas(item: any, tabelasRelacionadas: Array<ExclusaoRegistroDto>) {
    item.tabelasRelacionadas = tabelasRelacionadas;
  }

  static getDadosEscolaLotacaoAee() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.escolaLotacaoAee`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static getCurrentUtbEscola() {
    const utb = localStorage.getItem(`${NAME_PROJECT}.utbEscola`);
    return utb ? JSON.parse(atob(utb)) : null;
  }

  /**
* @param fieldNames array com o nome dos formControls do formulário
* @param form formulário a ser percorrido e ter seus campos resetadosa
*/
  static resetarCampos(fieldNames: string[], form: FormGroup) {
    const fields = fieldNames.map(fieldName => form.get(fieldName));

    for (const field of fields) {
      field.reset();
    }
  }

  static downloadFromBack(arquivo: any, arquivoService: ArquivoService): void {
    arquivoService.download(arquivo.id.toString()).subscribe(res => {
      if (res.body.base64) {
        this.downloadPdfFile(res.body.base64, arquivo.nomeOriginal || arquivo.nome);
        return;
      }

      this.downloadFileByFileStream(res.body, arquivo.nome);
    }, (err) => { });
  }

  static downloadPdfFile(base64: string, fileName: string): void {
    const downlodedFile = Util.convertBase64ToBlobData(base64, 'application/pdf');
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  static downloadFileByFileStream(base64: any, fileName: string): void {
    const downlodedFile = new Blob([base64], { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  static retornarTipoExtensao(extensao: string): string {
    let type = '';
    switch (extensao.toLowerCase()) {
      case FormatoArquivo.PDF:
        type = 'application/pdf';
        break;
      case FormatoArquivo.JPG:
        type = 'image/jpg';
        break;
      case FormatoArquivo.JPEG:
        type = 'image/jpeg';
        break;
      case FormatoArquivo.PNG:
        type = 'image/png';
        break;
    }

    return type;
  }

  static downloadFileXlsx(response: any, name: string) {
    name = `${name}_${moment().format('DD-MM-yyyy')}.xlsx`.replace(/\s/g, '-');
    const blob = new Blob([response.body], {
      type: 'application/vnd.ms-excel;charset=utf-8'
    });
    saveAs(blob, name);
  }

  static getBrasilCalendarLocale() {
    return {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado'
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      clear: 'Limpar',
      weekHeader: 'Semana'
    };
  }

  static formatDateToString(date: Date, formatoBrasileiro: boolean = true): string {
    if (!date) return null;

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa em 0, por isso o +1
    const year = date.getFullYear();

    if (formatoBrasileiro) {
      return `${day}/${month}/${year}`;
    }

    return `${year}-${month}-${day}`;
  }

  static baixarArquivoPeloId(arquivo:any, arquivoService: ArquivoService) {
    const id = arquivo.arquivoId ?? arquivo.id;
    arquivoService.download(id).subscribe({
      next: (responseArquivo:any) => {
        const blob = new Blob([responseArquivo.body], { type: `application/${arquivo.extensao}` });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${arquivo.nomeOriginal ?? arquivo.nome}.${arquivo.extensao}`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }

  static baixarArquivoComBase64(arquivo: any) {
    const blobFile = this.base64toBlob(arquivo.base64, arquivo.extensao);
    const url = window.URL.createObjectURL(blobFile);
    const tagAnchor = document.createElement('a');
    tagAnchor.href = url;
    tagAnchor.download = `${arquivo.nome}.${arquivo.extensao}`;
    tagAnchor.click();
    window.URL.revokeObjectURL(url);
  }

  static limparValidacaoPorCampos(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).clearValidators();
      form.get(field).updateValueAndValidity();
    });
  }

  static adicionarValidacaoPorCampos(fields: string[], validators: any[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).setValidators(validators);
      form.get(field).updateValueAndValidity();
    })
  }

  static desabilitarCampos(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field).disable();
    })
  }

  static filtrarRotasPorPermissao(permissionsService: NgxPermissionsService, routes: Routes) {
    return routes.filter(route => {
      if (route.path === '') {
        return true; // Sempre manter a rota vazia
      }

      if (!route.data?.permissoes || route.data.permissoes.length === 0) {
        return true; // Manter rotas que não têm permissões definidas
      }

      return route.data?.permissoes.some((permission: string) => permissionsService.getPermission(permission)); // Se tiver pelo menos uma permissão, mantém a rota
    });
  }

  static getUsuarioSession() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.usuario`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static convertNumberToReal(valor: number) {
    return valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
  }

  static convertNumberToRealSemCifrao(valor: number) {
    return valor.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }

  static formatarCPF(cpf: string): string {
    if (cpf) {
      cpf = cpf.replace(/\D/g, '');
      cpf = cpf.padStart(11, '0');
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return '-';
  }

  static assignProperties(target: any, source: any) {
    if (source) {
      Object.assign(target, source);
    }
  }

  static addRemoveLoading() {
    const loading = document.getElementById('loading');
    if (loading.className === 'loading') {
      loading.classList.remove('loading');
    } else {
      loading.classList.add('loading');
    }
  }

  static ordenarArray(array: any, propriedade, crescente = true) {
    return array.sort((a, b) => {
      // Verifica se a propriedade existe nos objetos
      if (a[propriedade] === undefined || b[propriedade] === undefined) {
        return 0;
      }
  
      // Compara os valores da propriedade
      if (a[propriedade] < b[propriedade]) {
        return crescente ? -1 : 1;
      }
      if (a[propriedade] > b[propriedade]) {
        return crescente ? 1 : -1;
      }
      return 0;
    });
  }  
}
