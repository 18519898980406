<div class="info-bar">
  <div class="container">
    <div class="row justify-content-end align-items-center">
      <ul class="nav-info">
        <li>
          <a [routerLink]="['/auth/logout']" class="nav-item active" title="Sair"> Sair </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="mt-5">
    <div class="d-flex">
      <div class="container">
        <div class="row">
          <h4>Selecione sua matrícula</h4>
        </div>
        <div class="row mt-5">
          <ng-container *ngFor="let item of matriculas">
            <div class="col-12 card-matricula">
              <div class="card p-3">
                <p class="card-info-title">
                  <p><strong>Matrícula:</strong>
                    {{ item.matricula }}
                  </p>
                  <p><strong>Unidade de Trabalho (UTB):</strong>
                    {{ item.utb }}
                  </p>
                  <p><strong>Função:</strong>
                    {{ item.funcao }}
                  </p>
                <div>
                  <button
                    (click)="getMatriculaSelected(item.profissionalMatriculaId)"
                    class="btn rounded-pill btn-primary col-auto d-inline float-right">
                    Acessar
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
