import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FooterStackedChartData } from '../chart-types';

@Component({
  selector: 'app-footer-stacked-chart',
  templateUrl: './footer-stacked-chart.component.html',
  styleUrls: ['./footer-stacked-chart.component.scss'],
})
export class FooterStackedChartComponent implements AfterContentChecked {
  @Input() labelButton = '';
  @Input() data: FooterStackedChartData;
  @Output() buttonClicked = new EventEmitter<string>();
  @ViewChild('line', { static: false }) divHello: ElementRef;

  ngAfterContentChecked(): void {
    const lineDiv = this.divHello?.nativeElement;
    const linePer = lineDiv?.children[0];

    const values = this.data.values;
    const calculo = values[1] === 0 ? 0 : (values[0] * 100) / values[1];
    const percentual = Math.round(calculo);
    if (linePer) {
      linePer.style.width = `${percentual}%`;
    }

    this.labelButton = values[0] === values[1] ? 'Editar' : 'Adicionar';
  }

  onClick(event) {
    this.buttonClicked.emit(event);
  }
}
