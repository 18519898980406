<ng-container [formGroup]="form">
  <p-autoComplete
    [disabled]="disabled"
    placeholder="Selecione"
    [id]="name"
    class="custom-auto-complete"
    [formControlName]="name"
    [delay]="900"
    dataKey="id"
    [suggestions]="optionsForAutocomplete"
    [forceSelection]="true"
    (completeMethod)="getOptions($event); onSearch.emit($event)"
    [field]="fieldName"
    [dropdown]="true"
    [emptyMessage]="emptyMessage"
    minLength="1"
    (onSelect)="onChange.emit($event)"
    [ngClass]="customClass"
    (focusout)="onFocusOut.emit($event)"
    (onClear)="onClear.emit($event)"
  >
  </p-autoComplete>
</ng-container>
