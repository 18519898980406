<div class="card mb-0">
    <div class="card-header">
      <h5 class="aviso mt-2">{{tituloModal}}</h5>
      <button id="closed" type="button" class="btn rounded-pill btn-default buttonX" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        x
      </button>
    </div>
    <div class="card-body">
      <h2 *ngIf="title" class="text-center">{{ title }}</h2>
      <h5 class="text-center">{{ message }}</h5>
    </div>
    <div class="d-flex justify-content-center">
        <div *ngFor=" let botao of botoesRelatorio; let i = index">
            <button
            [id]="'botao-' + botao.nomeBotao"
            class="btn btn-primary rounded-pill mb-4 mr-3 buttonSubmit"
            [title]="botao.nomeBotao"
            (click)="closeModal(botao.tipoRelatorio)"
            >
            <em *ngIf="botao.exibirIcone" [class]="botao.iconeClass"></em>
            &nbsp;
            {{ botao.nomeBotao }}
          </button>
        </div>
    </div>
  </div>  
