import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ArquivoService {
  private controller = 'arquivo';
  public baseUrl = environment.apiUrl;
  public namespaceProject = environment.namespaceProject;

  constructor(
    private httpService: HttpClient,
    private storageService: StorageService
  ) {}

  headerFileOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`)
      ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`)
      : '';

    return {
      headers: new HttpHeaders({
        Authorization: TOKEN,
      }),
      responseType: 'blob',
      observe: 'response',
    };
  }

  upload(endpoint: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const TOKEN = this.storageService.getFromLocalStorage('token')
      ? 'Bearer ' + this.storageService.getFromLocalStorage('token')
      : '';

    return this.httpService
      .post(`${this.baseUrl}${endpoint}`, formData, {
        headers: { Authorization: TOKEN },
        reportProgress: true,
      })
      .pipe(
        timeout(20000),
        catchError(() => {
          return of(null);
        })
      );
  }

  download(id: string): Observable<any> {
    return this.httpService
      .get(`${this.baseUrl}${this.controller}/${id}`, this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
}
