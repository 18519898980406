import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
  @Input() allowMultiLine = false;
  @Input() allowNegative = false;
  @Input() allowDecimal = true;
  @Input() allowLeadingZero = true;
  @Input() maxLength = 0;
  regex: RegExp;

  constructor(private readonly el: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.validate(event, event.key === 'Enter' ? '\n' : event.key);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: Event) {
    const pastedText = (window as any)?.clipboardData?.getData('Text') // If IE, use window
      || event as ClipboardEvent && (event as ClipboardEvent)?.clipboardData?.getData('text/plain'); // Non-IE browsers
    this.validate(event, pastedText);
  }

  @HostListener('cut', ['$event'])
  onCut(event: Event) {
    this.validate(event, '');
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    this.removeLeadingZeros();
  }

  validate(event: Event, text: string) {
    const txtInput = this.el.nativeElement;
    const newValue = (txtInput.value.substring(0, txtInput.selectionStart)
      + text + txtInput.value.substring(txtInput.selectionEnd));
    if (!this.regex) {
      // tslint:disable-next-line: no-eval
      this.regex = eval('/^'
        + (this.allowNegative ? '-?' : '')
        + (this.allowDecimal ? '((\\d+\\.?)|(\\.?))\\d*' : '\\d*')
        + '$/g') as RegExp;
    }
    const lines = this.allowMultiLine ? newValue.split('\n') : [newValue];
    for (const line of lines) {
      const lineText = line.replace('\r', '');
      if (this.maxLength && lineText.length > this.maxLength || !lineText.match(this.regex)) {
        event.preventDefault();
        return;
      }
    }
  }

  removeLeadingZeros() {
    const input = this.el.nativeElement;
    let value = input.value;

    if (this.allowLeadingZero) {
      return;
    }

    if (value === '0' || value === '-0') {
      return;
    }

    if (this.allowDecimal) {
      value = value.replace(/^(-)?0+(?=\d)/, '$1');
    } else {
      value = value.replace(/^(-)?0+/, '$1');
    }

    input.value = value;
  }
}
