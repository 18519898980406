<div class="row">
  <div class="col-12 col-md-8" >
    <h4 class="align-middle text-dark mt-4  font-weight-bold">
      <em [class]="icon"></em> {{ title }}
    </h4>
  </div>
  <div class="col-12 col-md-2 offset-md-2 mb-3">
    <ng-content select="[button]"></ng-content>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card box- mb-0">

      <div class="card-header d-none">
        <div class="card-title text-white">{{ subtitle }}</div>
        <div class="card-tools">
          <button
            type="button"
            class="btn rounded-pill text-primary"
            data-card-widget="collapse"
          >
            <em class="fas fa-minus"></em>
          </button>
        </div>
      </div>
      <div class="card-body">
        <ng-content select="[conteudo]"></ng-content>
      </div>
    </div>
  </div>
</div>
