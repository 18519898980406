<div class="form-group">
  <label *ngIf="label" [for]="name" [ngClass]="{
    'text-red': validateMessage?.messageDisplay[name] || isInvalid
  }"> {{ label }}
    <em *ngIf="tooltipTitle" class="fas fa-exclamation-circle text-danger" data-toggle="tooltip" data-placement="auto"
      [title]="tooltipTitle" onmouseenter="$(this).tooltip('show')">
    </em>
  </label>

  <div #content>
    <ng-content ></ng-content>
  </div>

  <small class="form-text required" *ngIf="required && !validateMessage?.messageDisplay[name] && !isInvalid">
    *obrigatório
  </small>

  <small class="form-text text-red" *ngIf="validateMessage?.messageDisplay[name]"
    [innerHTML]="validateMessage?.messageDisplay[name]">
  </small>
</div>
