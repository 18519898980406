import { Component, Input } from '@angular/core';
import { NormalizedHorizontalBarChartData } from '../chart-types';

@Component({
  selector: 'app-normalized-horizontal-bar-chart',
  templateUrl: './normalized-horizontal-bar-chart.component.html'
})
export class NormalizedHorizontalBarChartComponent {

  @Input() data: NormalizedHorizontalBarChartData[] = [];
  @Input() view: any[] = [500, 230];
  @Input() label: string = '';

  @Input() showXAxis: boolean = true;
  @Input() showYAxis: boolean = true;
  @Input() showLegend: boolean = false;
  @Input() showXAxisLabel: boolean = false;
  @Input() xAxisLabel: string = '';
  @Input() showYAxisLabel: boolean = false;
  @Input() yAxisLabel: string = '';
  @Input() animations: boolean = true;

  colorScheme = {
    domain: ['#6095c7', '#213242', '#AAAAAA']
  };

  constructor() {
    Object.assign(this.data)
  }

}
