<div class="card mb-0">
    <div class="card-header">
      <h5 class="title">Aviso</h5>
      <button type="button" class="btn rounded-pill btn-default buttonX" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        x
      </button>
    </div>
    <div class="card-body">
      <h5 class="text-center">{{ message }}</h5>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="submit"
        title="{{nameButton}}"
        (click)="closeModal()"
        class="btn btn-primary rounded-pill mb-4 buttonSubmit">
        {{nameButton}}
      </button>
    </div>
  </div>
  
  