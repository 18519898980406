import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-horizontal',
  templateUrl: './timeline-horizontal.component.html',
  styleUrls: [ './timeline-horizontal.component.scss' ]
})
export class TimelineHorizontalComponent {

  @Input() dadosTimeline;
  @Input() title: string = 'Acompanhamento:';

  getIconByStatus(status: any) {
    switch (status) {
      case 'Pendente':
        return 'fa-exclamation-circle';
      case 'Andamento':
        return 'fa-clock';
      case 'Finalizado':
        return 'fa-check-circle';
      default:
        return 'fa-exclamation-circle';
    }
  }

  getColorButtonByStatus(status: any) {
    switch (status) {
      case 'Pendente':
        return 'pendente';
      case 'Andamento':
        return 'andamento';
      case 'Finalizado':
        return 'finalizado';
      default:
        return 'pendente';
    }
  }

}
