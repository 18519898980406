import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    if (value === 'EM_ANDAMENTO') {
      return 'Em Andamento';
    }

    if (value === 'FINALIZADO') {
      return 'Finalizado';
    }
  }
}
