<div class="foto">
  <img [src]="imgSource" class="img-fluid" [alt]="imgAlt" *ngIf="imgSource">
</div>

<div class="d-flex align-items-center mt-2">
  <button
    type="submit"
    class="btn btn-sm rounded-pill btn-primary col"
    (click)="inputPhoto.click()"
  >
    {{submitText}}
  </button>
  <button
    class="btn btn-icon btn-sm ml-1 rounded-pill btn-primary col-auto"
    *ngIf="hasImage"
    (click)="clear()"
  >
    <em class="fas fa-trash"></em>
  </button>
</div>

<input type="file" name="inputPhoto" [accept]="accept" #inputPhoto class="d-none" (change)="onSelectFile($event)">
