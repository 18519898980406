import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-layout-auth',
  templateUrl: './layout-auth.component.html',
  styleUrls: ['./layout-auth.component.scss']
})
export class LayoutAuthComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    $('body').addClass('sidebar-mini layout-fixed');
  }

  hasOwnPageTitle() {
    return (
      this.router.url.includes('avaliacao-desempenho/servidores-avaliacao/pgdi/preencher') ||
      this.router.url.includes('avaliacao-desempenho/servidores-avaliacao/pgdi') ||
      this.router.url.includes('avaliacao-desempenho/servidores-avaliacao/pgdi/validar') ||
      this.router.url.includes('/avaliacao-desempenho/servidores-avaliacao/termo') ||
      this.router.url.includes('/avaliacao-desempenho/servidores-avaliacao/parecer') ||
      this.router.url.includes('/central-notificacoes')
    );
  }

}
