import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Util } from '../../utils/util';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() name: string = '';
  @Input() customClass: any = '';

  @Input() view: string = 'date';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() placeholder: string = 'dd/mm/aaaa';

  @Input() maxDate: Date = null;
  @Input() minDate: Date = null;

  @Input() yearRange = '2000:2030';
  @Input() showButtonBar = 'true';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFocusOut = new EventEmitter<any>();

  inputModel: Date = null;

  locale = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    // dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
    weekHeader: 'Semana',
  };

  get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  get disabled() {
    return this.formControl?.disabled;
  }

  ngOnInit(): void {
    this.formControl?.valueChanges.subscribe((value) => {
      this.inputModel = Util.formatStringToDate(value);
    });
    this.inputModel =
      this.formControl?.value &&
      Util.formatStringToDate(this.formControl?.value);
  }

  change(value) {
    this.formControl?.setValue(this.getValueFormatted(value));
    this.formControl?.markAsTouched();
    this.onChange.emit(this.formControl?.value);
    this.onFocusOut.emit();
  }

  blur() {
    this.formControl?.markAsTouched();
    this.onFocusOut.emit(this.formControl?.value);
  }

  getValueFormatted(value: any) {
    return value ? moment(value).format('YYYY-MM-DD HH:mm:SS') : '';
  }
}
