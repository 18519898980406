<div class="container-fluid">
  <h5 class="mt-2 mb-5">{{ title }}</h5>
  <div class="timeline">
    <div class="events">
      <div class="event">
        <p class="event-name-top vertical-left-1">Admissão</p>
        <div class="circle"></div>
        <p class="event-name-bottom vertical-right-1">{{ dadosTimeline?.dataAdmissao | date:"dd/MM/YYYY" }}</p>
      </div>
      <div class="event">
        <p class="event-name-top">1º PGDI</p>
        <div class="circle"></div>
        <button class="btn btn-sm btn-primary vertical-right-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.pgdi1)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.pgdi1)"></em>
          <span>{{ dadosTimeline?.pgdi1 || 'Pendente' }}</span>
        </button>
      </div>
      <div class="event">
        <button class="btn btn-sm btn-primary vertical-left-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.termo1)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.termo1)"></em>
          <span>{{ dadosTimeline?.termo1 || 'Pendente' }}</span>
        </button>
        <div class="circle"></div>
        <p class="event-name-bottom">1º Termo</p>
      </div>
      <div class="event">
        <p class="event-name-top">2º PGDI</p>
        <div class="circle"></div>
        <button class="btn btn-sm btn-primary button-right vertical-right-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.pgdi2)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.pgdi2)"></em>
          <span>{{ dadosTimeline?.pgdi2 || 'Pendente' }}</span>
        </button>
      </div>
      <div class="event">
        <button class="btn btn-sm btn-primary vertical-left-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.termo2)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.termo2)"></em>
          <span>{{ dadosTimeline?.termo2 || 'Pendente' }}</span>
        </button>
        <div class="circle"></div>
        <p class="event-name-bottom">2º Termo</p>
      </div>
      <div class="event">
        <p class="event-name-top">3º PGDI</p>
        <div class="circle"></div>
        <button class="btn btn-sm btn-primary vertical-right-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.pgdi3)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.pgdi3)"></em>
          <span>{{ dadosTimeline?.pgdi3 || 'Pendente' }}</span>
        </button>
      </div>
      <div class="event">
        <button class="btn btn-sm btn-primary vertical-left-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.termo3)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.termo3)"></em>
          <span>{{ dadosTimeline?.termo3 || 'Pendente' }}</span>
        </button>
        <div class="circle"></div>
        <p class="event-name-bottom">3º Termo</p>
      </div>
      <div class="event">
        <p class="event-name-top">Parecer</p>
        <div class="circle"></div>
        <button class="btn btn-sm btn-primary vertical-right-2" [ngClass]="getColorButtonByStatus(dadosTimeline?.parecer)">
          <em class="fas mr-1" [ngClass]="getIconByStatus(dadosTimeline?.parecer)"></em>
          <span>{{ dadosTimeline?.parecer || 'Pendente' }}</span>
        </button>
      </div>
      <div class="line"></div>
    </div>
  </div>
</div>

