import { Injectable } from '@angular/core';
import { URL_CONFIG } from '@core/utils/url-configs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NotificacaoHomeCardsDto } from '@core/dto/notificacao/notificacao-home-cards.dto';
import { NotificacaoHomeListDto } from '@core/dto/notificacao/notificacao-home-list.dto';
import { NotificacaoHomeUpdateDto } from '@core/dto/notificacao/notificacao-home-update.dto';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService {

  constructor(
    private apiService: ApiService,
  ) { }

  public getByFilter(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${URL_CONFIG.url_notificacao}${filters}`)
      .pipe(map(response => response.body.data));
  }

  public getNotificationsCards() {
    return this.apiService.get(`${URL_CONFIG.url_notificacao}/notificacoes-home-cards`)
    .pipe(map(response => new NotificacaoHomeCardsDto(response.body.data)));
  }

  public getAllNotifications(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${URL_CONFIG.url_notificacao}/notificacoes-home${filters}`)
      .pipe(map(response => new NotificacaoHomeListDto(response.body.data)));
  }

  public getArchivedNotifications(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${URL_CONFIG.url_notificacao}/notificacoes-arquivadas${filters}`)
      .pipe(map(response => new NotificacaoHomeListDto(response.body.data)));
  }

  public markNotificationAsReadById(id: string) {
    return this.apiService.put(`${URL_CONFIG.url_notificacao}/notificacoes-lidas/${id}`, id);
  }

  public markCheckedNotificationsAsRead(params?: any) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.put(`${URL_CONFIG.url_notificacao}/notificacoes-lidas-arquivadas${filters}`, filters);
  }


}
