import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteDto } from 'src/app/core/dto/auto-complete-dto';
import { AutocompleteService } from 'src/app/core/services/autocomplete.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {
  @Input() searchFor: 'nome' | 'descricao' = 'nome';
  @Input() form: FormGroup;
  @Input() disabled: boolean = false;
  @Input() name: string = '';
  @Input() customClass: any = '';
  @Input() url: any = '';
  @Input() params: any = {};
  @Input() customOptions: Array<any>;
  @Input() forbidden: any[] = [];
  @Input() fieldName = 'nome';
  @Input() key = 'id';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFocusOut = new EventEmitter<any>();

  options: AutocompleteDto[] = [];

  constructor(private autocompleteService: AutocompleteService) {}

  ngOnInit(): void {
    if (this.customOptions) {
      this.onValueChange();
      if (typeof this.form.get(this.name).value === 'string') { this.selectToAutocomplete(); }
    }
  }

  onValueChange() {
    this.form.get(this.name).valueChanges.subscribe(value => {
      if (typeof value === 'string') {
        this.selectToAutocomplete();
      }
    });
  }

  selectToAutocomplete() {
    this.form.get(this.name).setValue(
      this.customOptions.find(
        (x) => x.value === this.form.get(this.name).value
      )
    );
  }

  getOptions(event?: any) {
    if (this.customOptions) {
      this.options = [...this.customOptions];
      if (event?.query) {
        this.options = [...this.customOptions].filter(
          (x) =>
            x.value?.toUpperCase().includes(event.query.trim().toUpperCase()) ||
            x.name?.toUpperCase().includes(event.query.trim().toUpperCase())
        );
      }
      return;
    }

    this.autocompleteService
      .getForSelectFromObject(this.url, {
        [ this.searchFor ]: event?.query,
        ...this.params,
      })
      .subscribe((response) => {
        const filteredsOptions = response.filter(
          (item) =>
            !this.forbidden.find(
              (forbiddenItem) =>
                forbiddenItem &&
                forbiddenItem.id.includes(item.id) &&
                !forbiddenItem.id.includes(this.form.get(this.name).value?.id)
            )
        );
        this.options = filteredsOptions;
      });
  }

  get optionsForAutocomplete() {
    return this.options;
  }
}
