import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsInitService {
  constructor(private permissionsService: NgxPermissionsService, private storageService: StorageService
  ) {}

  initializePermissions() {
    const permissaoPadrao = this.storageService.getFromLocalStorage('permissaoUsuario', true);
    if (permissaoPadrao) {
      this.permissionsService.addPermission(permissaoPadrao);
    }
    const permissoesUsuario = this.storageService.getFromLocalStorage('permissoes', true);
    if (permissoesUsuario) {
      this.permissionsService.addPermission(permissoesUsuario);
    }
  }

}
