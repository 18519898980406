import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected baseUrl = environment.apiUrl;

  constructor(private readonly httpClient: HttpClient, private readonly storageService: StorageService) {}

  HeaderOptions(): any {
    const TOKEN = this.storageService.getFromLocalStorage('token')
    ? 'Bearer ' + this.storageService.getFromLocalStorage('token')
    : '';
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: TOKEN,
      }),
      observe: 'response',
    };
  }

  headerOptionsExcel(): any {
    const TOKEN = this.storageService.getFromLocalStorage('token')
      ? 'Bearer ' + this.storageService.getFromLocalStorage('token')
      : '';
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: TOKEN
      }),
      responseType: 'blob' as 'blob',
      observe: 'response' as 'response'
    };
  }

  /**
   * Generic GET Method
   * @param path URL path
   */
  public get(path: string): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + path, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic GET Method Para download de excel
   * @param path URL path
   */
  public getExcel(path: string): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + path, this.headerOptionsExcel())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }  

  /**
   * Generic POST Method
   * @param path URL path
   * @param body Request body
   */
  public post(path: string, body: any): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic PUT Method
   * @param path URL path
   * @param body Request body
   */
  public put(path: string, body: any): Observable<any> {
    return this.httpClient
      .put(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic PATCH Method
   * @param path URL path
   * @param body Request body
   */
  public patch(path: string, body: any): Observable<any> {
    return this.httpClient
      .patch(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic DELETE Method
   * @param path URL path
   */
  public delete(path: string): Observable<any> {
    return this.httpClient
      .delete(this.baseUrl + path, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public getForSelect(path: string, params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpClient.get(
      this.baseUrl + path + filters,
      this.HeaderOptions()
    );
  }

  public getQueryParamsForFilter(obj: object = {}) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value instanceof Array) {
        value.forEach(v => {
          params.append(key, v);
        })
      } else if (typeof value === 'boolean' || typeof value === 'number' || value) {
        params.set(key, obj[key]);
      }
    });
    return params;
  }
}
