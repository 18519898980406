import { Directive } from '@angular/core';

@Directive({
  selector: '[customColumns]'
})
export class CustomColumnsDirective {

  constructor() { }

}
