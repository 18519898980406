import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { NormalizedHorizontalBarChartData } from '@shared/utils/chart-types';
import { ColorsGraphArray } from '@shared/utils/colors-graph';


@Component({
  selector: 'app-vertical-stacked-bar',
  templateUrl: './vertical-stacked-bar.component.html'
})
export class VerticalStackedBarComponent {

  @ViewChild('containerRef') containerRef!: ElementRef;
  @Input() id: string ='vertical-chart';
  @Input() data: NormalizedHorizontalBarChartData[] = [];
  @Input() view: any[] = [500, 230];
  @Input() label: string = '';
  @Input() showXAxis: boolean = true;
  @Input() showYAxis: boolean = true;
  @Input() showLegend: boolean = false;
  @Input() showXAxisLabel: boolean = false;
  @Input() xAxisLabel: string = '';
  @Input() showYAxisLabel: boolean = false;
  @Input() yAxisLabel: string = '';
  @Input() animations: boolean = true;
  @Input() barPadding: number;
  @Input() legendPosition: string = '';
  @Input() legendTitle: string = '';
  @Input() showGridLines: boolean = false;
  @Input() showDataLabel: boolean = false;
  @Input() colorScheme = {
    domain: [...ColorsGraphArray]
  };

  tempValues: number[] = [];

  constructor(public cdr: ChangeDetectorRef) {
    Object.assign(this.data);
  }

  @HostListener('window:resize')
  resizeChart() {
    if (this.containerRef) {
      const width = this.containerRef.nativeElement.offsetWidth;
      this.view = [width, 300];
    }
  }

  formatter (val: any)  {
    return val;
  }

}
