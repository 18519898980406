import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatriculaService } from '@core/services/matricula.service';
import { StorageService } from '@core/services/storage.service';
import { MSG_SEM_ACESSO_INFORMACOES_DO_SISTEMA } from 'src/app/shared/utils/constants';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-selecionar-matricula',
  templateUrl: './selecionar-matricula.component.html',
  styleUrls: ['./selecionar-matricula.component.scss']
})
export class SelecionarMatriculaComponent implements OnInit {

  private namespaceProject = environment.namespaceProject;
  public matriculas;
  profissionalMatriculaId;
  dados;

  constructor(
    public matriculaService: MatriculaService,
    public router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.matriculas = this.storageService.getFromLocalStorage('profissionalMatriculas', true);
  }

  getMatriculaSelected(profissionalMatriculaId) {
    this.profissionalMatriculaId = profissionalMatriculaId;
    this.getPerfilUsuarioByMatricula();
  }

  getPerfilUsuarioByMatricula() {
    this.matriculaService.getDadosUsuarioByMatricula(this.profissionalMatriculaId)
    .subscribe((response) => {
      this.storageService.setInLocalStorage('perfilAcesso', response.perfilAcesso);
      this.storageService.setInSessionStorage('perfilAcesso', response.perfilAcesso);
      this.storageService.setInLocalStorage('profissionalMatriculaId', this.profissionalMatriculaId);
      this.storageService.setInLocalStorage('usuario-matricula', response);
      this.storageService.setInLocalStorage('funcao', response.funcao);
      this.router.navigate([ '/' ]);
    }, (error) => {
      SweetalertCustom.newShowAlertConfirm('warning', 'Atenção',
      MSG_SEM_ACESSO_INFORMACOES_DO_SISTEMA,
       'Voltar')
        .then((resp) => {
          if (resp.isConfirmed) {
            this.router.navigate([ '/' ]);
          }
        });
    });
  }
}
