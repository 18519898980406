<footer>
  <div class="container h-100">
    <div class="row justify-content-between align-items-center h-100 w-100">
      <span>{{ anoAtual }}| Governo da Paraíba</span>

      <a routerLink="/">
        <img src="assets/img/siage-logo-negative.png" alt="logo siage" class="logo" />
      </a>

    </div>
  </div>
</footer>
