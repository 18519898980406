import { Component, Input } from '@angular/core';
import { GenericChartData } from '../chart-types';
import { ColorsGraphArray } from '../colors-graph';

@Component({
  selector: 'app-vertical-custom-bar',
  templateUrl: './vertical-custom-bar.component.html'
})
export class VerticalCustomBarComponent {

  @Input() data: GenericChartData[] = [];
  @Input() label: string = '';
  @Input() showCardBody: boolean = true;
  @Input() view: any[] = [200, 220];

  @Input() showXAxis = true;
  @Input() showYAxis = false;
  @Input() gradient = false;
  @Input() showLegend = false;
  @Input() showXAxisLabel = false;
  @Input() xAxisLabel = '';
  @Input() showYAxisLabel = false;
  @Input() yAxisLabel = '';
  @Input() roundEdges = false;
  @Input() barPadding = '';
  @Input() yScaleMin = '';
  @Input() yScaleMax = '';
  @Input() trimXAxisTicks = true;
  @Input() showMessageDataIsEmpty = true;
  @Input() legendPosition: string = 'right';
  @Input() legendTitle: string = '';
  @Input() labelTooltip: string = '';
  @Input() tooltipTemplate: string = '';
  @Input() colorScheme = {
    domain: [...ColorsGraphArray]
  };
  constructor() {
    Object.assign(this.data);
  }

  get dataIsEmpty(): boolean {
    return this.data?.every((x) => x.value === 0);
  }

  get showEmptyChart(){
    if (!this.showMessageDataIsEmpty){
      return true;
    }
    if (this.dataIsEmpty){
      return false;
    }
    return true;
  }

  onSelect(event) { }

}
