export class NotificacaoHomeListDto {
  public id: string;
  public titulo: string;
  public texto: string;
  public codigoProcesso: string;
  public lida: boolean;
  public arquivada: boolean;
  public chefeImediato?: string;
  public comissao?: string;
  public servidor?: string;
  public checked?: boolean;
  public expanded?: boolean;
  public profissionalMatriculaId: string;
  public tipoProcesso: string;

  constructor(object?: any) {
    if (object) {
      Object.assign(this, object);
    }
  }
}
