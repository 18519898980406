import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SweetalertCustom } from './../../utils/sweetalert-custom';
import { MSG_ALOCAR_PROFESSOR } from '../../utils/constants';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent implements OnInit {
  @Input() title: string[] = ['', ''];
  @Input() list1: any[];
  @Input() list2: any[];
  @Input() propertyToUpdate: string; // atributo do objeto a ser atualizado
  @Input() optionsOfProperty: any[2]; // opções que o atributo pode ter lista 1 e lista 2
  @Input() childTemplate: TemplateRef<any>;

  component = `<div class="two">two</div>`

  constructor() {}

  ngOnInit() {
    if(this.professorAlocadoIsNullOrEmpty){
      this.list2 = [];
    }
  }

  get professoresDisponiveisIsEmpty(){
    return this.list1?.length === 0
  }

  get professorAlocadoIsNullOrEmpty(){
    return this.list2?.length === 0 || this.list2 == null;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

    } else {
      if(this.list2.length > 0 && event.container.data === this.list2){
        SweetalertCustom.newShowAlertConfirm('warning', 'Atenção!', MSG_ALOCAR_PROFESSOR);
        return;
      }

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.updateList();
    }
  }

  updateList() {
    if (this.propertyToUpdate && this.optionsOfProperty) {
      this.list1.forEach(x => x[this.propertyToUpdate] = this.optionsOfProperty[0]);
      this.list2.forEach(x => x[this.propertyToUpdate] = this.optionsOfProperty[1]);
    }
  }
}
