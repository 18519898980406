import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page-title-custom',
  templateUrl: './page-title-custom.component.html',
  styleUrls: ['./page-title-custom.component.scss']
})
export class PageTitleCustomComponent implements OnInit, OnDestroy {

  @Input() viewButtonExpansive?: boolean = false;
  @Output() clickButtonExpansive = new EventEmitter();
  public expanded: boolean = true;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute)
  {  }

  public menuItems: MenuItem[] = [];
  private _subscriptionRouter: Subscription;

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this._subscriptionRouter = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(
        () =>
          (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
      );
  }

  ngOnDestroy(): void {
    this._subscriptionRouter.unsubscribe();
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {

      const label = child.snapshot.data['breadcrumb'];
      const title = child.snapshot.data['title'] || label;
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      if (label) {
        breadcrumbs.push({ label, url, title });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  get pageTitle(): string {
    const lastUrl = this.menuItems[this.menuItems.length - 1];
    return lastUrl?.title || lastUrl?.label;
  }

  expansive() {
    this.expanded = !this.expanded;
    this.clickButtonExpansive.emit(this.expanded);
  }


}
