<div class="card mb-0">
  <div class="card-header">
    <h5 class="aviso mt-2">{{titleMsg}}</h5>
    <button id="closed" type="button" class="btn rounded-pill btn-default buttonX" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      x
    </button>
  </div>
  <div class="card-body">
    <h2 *ngIf="title" class="text-center">{{ title }}</h2>
    <h5 class="text-center">{{ message }}</h5>
  </div>
  <div class="d-flex justify-content-center" *ngIf="isOneButton">
    <button
      id="button-submit"
      type="submit"
      [title]="nameButton"
      (click)="submit()"
      class="btn btn-primary rounded-pill mb-4 buttonSubmit">
      {{ nameButton }}
    </button>
  </div>
  <div class="d-flex justify-content-center" *ngIf="!isOneButton">
    <button
      id="button-confirm"
      type="submit"
      title="Sim"
      (click)="submit()"
      class="btn btn-primary rounded-pill mb-4 buttonSubmit">
      {{ nameButtonConfirm }}
    </button>
    <button
      id="button-cancel"
      type="submit"
      title="Não"
      (click)="closeModal()"
      class="btn btn-outline-primary rounded-pill mb-4 buttonSubmit ml-2">
      {{ nameButtonCancel }}
    </button>
  </div>
</div>

