import { AutocompleteDto } from '../auto-complete-dto';

export class UsuarioFormDTO {
  public id: string;
  public nome: string;
  public sobrenome: string;
  public cpf: string;
  public email: string;
  public telefone: string;
  public primeiroAcesso: boolean;
  public perfilId: AutocompleteDto;
  public ativo: boolean;
  public dataCriacao: Date;
  public dataAlteracao?: Date;

  constructor(usuario?: any) {
    if (usuario) {
      this.id             = usuario.id;
      this.nome           = usuario.nome;
      this.sobrenome      = usuario.sobrenome;
      this.cpf            = usuario.cpf;
      this.email          = usuario.email;
      this.telefone       = usuario.telefone;
      this.primeiroAcesso = usuario.primeiroAcesso;
      this.perfilId       = usuario.perfilId;
      this.ativo          = usuario.ativo;
      this.dataCriacao    = usuario.dataCriacao;
      this.dataAlteracao  = usuario.dataAlteracao;
    }
  }
}
