

<div class="form-group">
  <label for="" [ngClass]="{'invisible': !label}">{{label || 'Search'}}</label>
  <div class="input-group  search-form">
    <input type="text" class="form-control" [placeholder]="placeholder">
    <div class="input-group-append">
      <button class="btn" type="button">
        <em class="fa fa-search"></em>
      </button>
    </div>
  </div>
</div>
