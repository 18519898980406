import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { StorageService } from '@core/services/storage.service';
import { Util } from 'src/app/shared/utils/util';

declare var $: any;

@Component({
  selector: 'app-sidebar-auth',
  templateUrl: './sidebar-auth.component.html',
  styleUrls: ['./sidebar-auth.component.scss']
})
export class SidebarAuthComponent implements OnInit, AfterContentChecked {

 public usuarioSession;
 public perfilDescription;

  constructor( private storageService: StorageService) { }

  ngOnInit(): void {
    $(document).ready(() => {
      $('[data-widget="treeview"]').Treeview('init');
      $('.menu-item').click(() => {
        $('.nav-icon').toggleClass('open');
      });
    });
    this.getCurrentUserSession();
  }

  ngAfterContentChecked() {
    this.activeSideBar();
  }

  public activeSideBar() {
    const baseUrl = window.location.origin;
    const url = window.location.href.replace(baseUrl, '');
    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).removeClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .removeClass('menu-open')
      .prev('a')
      .removeClass('active');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).addClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .addClass('menu-open')
      .prev('a')
      .addClass('active');
  }

  public getCurrentUserSession() {
    this.usuarioSession = this.storageService.getFromLocalStorage('usuario',true);
    this.perfilDescription = this.storageService.getFromLocalStorage('perfilAcesso');
  }

}
