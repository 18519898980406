import { Injectable } from '@angular/core';
import { URL_CONFIG } from '@core/utils/url-configs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MatriculaService {

  constructor(
    private apiService: ApiService,
  ) { }

  public getDadosUsuarioByMatricula(matricula: string) {
    return this.apiService.get(`${URL_CONFIG.url_matricula}/profissional-matricula-acesso/${matricula}`)
    .pipe(map(response => response.body.data));
  }

  public getInfoById(profissionalMatriculaId: string) {
    return this.apiService.get(`${URL_CONFIG.url_matricula}/get-info/${profissionalMatriculaId}`)
    .pipe(map(response => response.body.data));
  }

}
