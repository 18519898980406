<div class="card mb-0">
    <div class="card-header d-flex justify-content-between align-items-center">
        <div class="card-body">
            <form [formGroup]="form">
            <div class="d-flex flex-grow-1">
                <h6 class="aviso">Redigite sua Senha</h6>
            </div>
            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <div class="input-group rounded-pill">
                            <input
                            id="password"
                            type="password" 
                            class="form-control rounded-pill" 
                            placeholder="Digite sua senha..." 
                            formControlName="password"
                            minLength="3"
                            [ngClass]="setErrorValidate('password')"
                            />
                        </div>
                        <small 
                        class="form-text text-red" 
                        *ngIf="validateMessage.messageDisplay.password"
                        [innerHTML]="validateMessage.messageDisplay.password"
                        ></small>
                    </div>
                </div>
            </div>      
            <div class="row">
                <div class="col-12 col-md-12">
                    <button 
                        id="closeModal"
                        type="submit" 
                        title="Cancelar" 
                        (click)="closeModal()" 
                        class="btn btn-outline-danger rounded-pill mb-4">
                        <em class="fa fa-times"></em> Cancelar
                    </button>
                    <button 
                        id="verificarSenha"
                        type="submit" 
                        title="Salvar" 
                        (click)="verificarSenha()" 
                        [disabled]="enableShipping()"
                        class="btn btn-outline-primary rounded-pill mb-4 ml-2 float-right">
                        <em class="fa fa-check"></em> Assinar
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>
  </div>