
<div [ngClass]="{'card': showCardBody}">
    <div [ngClass]="{'card-body': showCardBody}">
      <div>
        <div class="row">
          <ng-content select="[button-top]"></ng-content>
          <div #ref class="col-8 d-flex flex-column justify-content-center">
            <ng-content select="[content]" class="d-flex"></ng-content>
          </div>
          <ng-content select="[header]" class="d-flex"></ng-content>
          <div class="label text-center">
            <h5><strong>{{label}}</strong></h5>
            <div *ngIf="showEmptyChart" id="chart" class="d-flex justify-content-center mt-3">
              <ngx-charts-bar-vertical
                [view]="view"
                [scheme]="colorScheme"
                [results]="data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [animations]="false"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [legendTitle]="legendTitle"
                [legendPosition]="legendPosition"
                [yScaleMin]="yScaleMin"
                [yScaleMax]="yScaleMax"
                [barPadding]="barPadding"
                [trimXAxisTicks]="trimXAxisTicks"
                (select)="onSelect($event)">
                <ng-template #tooltipTemplate let-model="model">
                  {{labelTooltip}} {{ model.value }}
                </ng-template>
              </ngx-charts-bar-vertical>
              <ng-content select="[right-content]" class="d-flex"></ng-content>
            </div>
            <div class="row container-fluid mt-4" style="height: 240px;" *ngIf="!showEmptyChart">
              <p>
                Não há dados a serem exibidos.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ng-content select="[footer]" class="d-flex"></ng-content>
    </div>
  </div>
  