import { Directive } from "@angular/core";

@Directive({
    selector: '[customActions]'
  })
  export class CustomActionsDirective {
  
    constructor() { }
  
  }
  