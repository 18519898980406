<div class="cards-container">
  <ng-container *ngFor="let card of cards">
    <div class="card ml-4 mr-4" (click)="filterCard(card)">
      <h3 class="card-title">{{card.title}}</h3>
      <p class="p-content">
        <span class="qtd">{{card.quantidade}}</span>
        {{card.qtdDescription}}
      </p>
    </div>
  </ng-container>
</div>
