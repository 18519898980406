export class PaginacaoDto<T> {
  public currentPage?: number;
  public pageCount?: number;
  public total?: number;
  public numPerPage?: number;
  public ultimaPagina?: number;
  public data?: Array<T> = new Array<T>();
  public stream?: any = null;

  constructor(object?: any) {
    if (object) {
      this.currentPage = object.currentPage;
      this.pageCount = object.pageCount;
      this.total = object.total;
      this.numPerPage = object.numPerPage;
      this.ultimaPagina = object.ultimaPagina;
      this.data = object.data;
      this.stream = object.stream;
    }
  }
}
