import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(
                error => this.handleError(request, error)
            )
        );
    }

    handleError(req: HttpRequest<any>, event) {
        // Desativa o Loading
        if (event instanceof HttpErrorResponse) {
            if (event.status === 401) {
                this.router.navigate(['/auth/logout']);
            }
        }

        return throwError(event);
    }
}
