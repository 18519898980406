import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { PaginacaoDto } from '@core/dto/paginacao-dto';
import { TableBodyDirective } from './config/table-body.directive';
import { TableEmptyDirective } from './config/table-empty.directive';
import { TableFooterDirective } from './config/table-footer.directive';
import { TableHeaderDirective } from './config/table-header.directive';

@Component({
  selector: 'app-table-custom',
  templateUrl: './table-custom.component.html'
})
export class TableCustomComponent implements OnInit {

  @ContentChild(TableHeaderDirective, { static: true, read: TemplateRef }) appTableHeader;
  @ContentChild(TableBodyDirective, { static: true, read: TemplateRef }) appTableBody;
  @ContentChild(TableFooterDirective, { static: true, read: TemplateRef }) appTableFooter;
  @ContentChild(TableEmptyDirective, { static: true, read: TemplateRef }) appTableEmpty;

  @Input() paginationId;
  @Input() items = new Array();
  @Input() itemsPerPage = 10;
  @Input() paginator = true;
  @Input() paginatorCustom = false;
  @Input() classPaginator = 'bcg-navy';
  @Input() isPaginatorSelect: boolean = false;
  @Input() hasLimitPage: boolean = false;

  // Paginaçao por demanda
  @Input() pageArray: Array<number> = [10];
  @Input() public page: PaginacaoDto<any>;
  @Output() changePage: EventEmitter<number> = new EventEmitter();
  @Output() changePageQtd: EventEmitter<number> = new EventEmitter();

  public pageCurrent = 1;

  ngOnInit() {
    if (!this.items) {
      this.items = [];
    }
  }

  public get getItemsPerPage(): number {
    return this.page?.numPerPage ?? this.itemsPerPage;
  }

  public get getItems(): Array<object> {
    return this.page?.data ?? this.items;
  }

  public get getCurrentPage(): number {
    return this.page?.currentPage ?? this.pageCurrent;
  }

  public setCurrentPage(page, emitEvent: boolean = true): void {
    if (this.page) {
      if(this.hasLimitPage){
        if (this.page?.currentPage === this.page.ultimaPagina -1) {
          this.page.total = this.getTotalItems;
        }
      }
      emitEvent && this.changePage.emit(page);
      return;
    }

    this.pageCurrent = page;
  }

  public setCurrentPageDinamic(page): void {
    if (this.page) {
      if (this.page?.currentPage === this.page.ultimaPagina -1) {
        this.page.total = this.getTotalItems;
      }
      this.changePage.emit(page);
      return;
    }
    this.pageCurrent = page;
  }

  public get getTotalItems(): number {
    return this.page?.total ?? this.items.length;
  }

  public setQtdItems(qtdItems){
    if (this.page) {
      this.page.numPerPage = qtdItems;
      this.page.pageCount = qtdItems;
      this.changePageQtd.emit(this.page.numPerPage);
      this.changePage.emit(1);
    }
    else{
      this.itemsPerPage = qtdItems;
      this.setCurrentPage(1)
    }
  }

}

