import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlAccessGuard implements CanActivate {

  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const perfilLogado = this.storageService.getFromLocalStorage('perfilAcesso',true);
      if (perfilLogado){
        const routerUsuariosAcesso = next.data.userAccess;
        if (routerUsuariosAcesso){
          const hasAccess = this.userHasAccess(routerUsuariosAcesso, perfilLogado);

          if (!hasAccess) {
            this.router.navigate(['']);
            return false;
          }
        }
      }

      const profiles = this.getProfilesRouteOrFirstParentRoute(next);

      if (profiles.length) {
        return this.validateProfilePermission(profiles, perfilLogado);
      }

      return true;
  }

  userHasAccess(userAcessList: any, userPerfil: any){
    return userAcessList.some(perfis => {
      return userPerfil.toUpperCase()
        .includes(perfis.toUpperCase());
    });
  }

  isIsFirsAccess(usuario: any) {
    if (usuario !== null && usuario.cadastroCompleto && usuario.primeiroAcesso) {
      this.router.navigate(['/auth/primeiro-acesso']);
      return false;
    }
  }

  public validateProfilePermission(profiles: any, perfilAcesso: any) {
    if (profiles.includes(perfilAcesso?.toLowerCase())) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }

  /**
   * Obtém um array de perfis associados a uma rota específica ou à primeira rota pai que possui perfis.
   *
   * @param {ActivatedRouteSnapshot} route - O snapshot da rota atual.
   * @returns {string[]} Um array de strings representando os perfis associados.
   *
   * Se a rota atual tem dados de perfil, esses perfis são retornados. Caso contrário, a função verifica recursivamente nas rotas pai,
   * até encontrar uma rota com perfis ou até que não haja mais rotas pai. Isso é útil para lidar com casos em que os perfis
   * podem estar definidos em um nível superior na hierarquia de rotas.
   */

  private getProfilesRouteOrFirstParentRoute(route: ActivatedRouteSnapshot): string[] {
    const profiles = [];

    if (route.data?.profiles) {
      profiles.push(...route.data.profiles);
    } else if (route.parent && !profiles.length) {
      profiles.push(...this.getProfilesRouteOrFirstParentRoute(route.parent));
    }

    return profiles.map((x: string) => x?.toLowerCase());
  }

}
