import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { CryptoJSConfig } from '../config/crypto-js.config';

const namespace: string = environment.namespaceProject;
const SECRET_KEY: string = CryptoJSConfig.getKey();

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public setInLocalStorage(keyName: string, value: any): void {
    const encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      SECRET_KEY
    ).toString();
    localStorage.setItem(`${namespace}.${keyName}`, encryptedValue);
  }

  public getFromLocalStorage(
    keyName: string,
    convertToJson: boolean = false
  ): any {
    const encryptedValue = localStorage.getItem(`${namespace}.${keyName}`);
    if (encryptedValue) {
      const decryptValue = CryptoJS.AES.decrypt(
        encryptedValue,
        SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);

      if (convertToJson) {
        return JSON.parse(decryptValue);
      } else {
        return decryptValue.replace(/^"|"$|"/g, '');
      }
    }
    return null;
  }

  public clearLocalStorage() {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key.includes(namespace)) {
        localStorage.removeItem(key);
      }
    }
  }

  public setInSessionStorage(keyName: string, value: any): void {
    const encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      SECRET_KEY
    ).toString();
    sessionStorage.setItem(`${namespace}.${keyName}`, encryptedValue);
  }

  public getFromSessionStorage(
    keyName: string,
    convertToJson: boolean = false
  ): any {
    const encryptedValue = sessionStorage.getItem(`${namespace}.${keyName}`);
    if (encryptedValue) {
      const decryptValue = CryptoJS.AES.decrypt(
        encryptedValue,
        SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);

      if (convertToJson) {
        return JSON.parse(decryptValue);
      } else {
        return decryptValue;
      }
    }
    return null;
  }

  public clearSessionStorage() {
    for (let i = sessionStorage.length - 1; i >= 0; i--) {
      const key = sessionStorage.key(i);
      if (key.includes(namespace)) {
        sessionStorage.removeItem(key);
      }
    }
  }

  public isUserAdministrador(): boolean {
    const perfilAcesso = this.getFromLocalStorage('perfilAcesso', true);
    if (perfilAcesso) {
      return perfilAcesso.toUpperCase() === 'ADMINISTRADOR';
    }
    return false;
  }

  public isUserServidor(): boolean {
    const perfilAcesso = this.getFromLocalStorage('perfilAcesso', true);
    if (perfilAcesso) {
      return perfilAcesso.toUpperCase() === 'SERVIDOR';
    }
    return false;
  }

  public isUserChefeImediato(): boolean {
    const perfilAcesso = this.getFromLocalStorage('perfilAcesso', true);
    if (perfilAcesso) {
      return perfilAcesso.toUpperCase() === 'CHEFEIMEDIATO';
    }
    return false;
  }

  public podeAcessarFerias(): boolean {
    const podeAcessarFerias = this.getFromLocalStorage('temAcessoAFerias');
    if (podeAcessarFerias) {
      return podeAcessarFerias.toUpperCase() === 'TRUE';
    }
    return false;
  }

  public isUserComissaoAvaliacao(): boolean {
    const perfilAcesso = this.getFromLocalStorage('perfilAcesso', true);
    if (perfilAcesso) {
      return perfilAcesso === 'COMISSAOAVALIACAO';
    }
    return false;
  }

  public getProfissionalMatriculaId(): string {
    return this.getFromLocalStorage('profissionalMatriculaId', true);
  }

  public getNomeUsuario(): string {
    return this.getFromLocalStorage('nome', true);
  }
}
