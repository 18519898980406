import { Router } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { SweetalertCustom } from '@shared/utils/sweetalert-custom';
import { NgxPermissionsService } from 'ngx-permissions';

export const PERMISSOES = Object.freeze({
  ADMIN: 'ADMIN',
  CHEFEIMEDIATO: 'CHEFEIMEDIATO',
  SERVIDOR: 'SERVIDOR',
  ConsultaProfissionais_exportar_pdf_excel: 'Consulta de Profissionais - Exportar (PDF e Excel)',
  ConsultaProfissionais_visualizar: 'Consulta de Profissionais - Visualizar Profissional',
  ComissaoAvaliacao_editar_gre: 'Comissões de Avaliação - Editar GRE',
  ComissaoAvaliacao_visualizar_gre: 'Comissões de Avaliação - Visualizar GRE',
  GerarResenha_download_excel_pdf: 'Gerar resenha - Gerar Resenha, Download Excel/PDF',
  SolicitacaoAfastamento_visualizar: 'Gerenciar Solicitações de Afastamento (Gerencial) - Visualizar Solicitação/Informação de Afastamento',
  HistoricoResenha_download_excel_pdf: 'Histórico de resenha - Histórico de resenha, Download Excel/PDF',
  ImportacaoProfissionalEmail_adicionar_carga: 'Importação de E-mail - Adicionar Carga',
  ImportacaoProfissionalEmail_visualizar_exportar: 'Importação de E-mail - Visualizar Pendencias, Exportar Pendencias',
  ImportacaoProfissional_adicionar_carga: 'Importação de Profissionais - Adicionar Carga',
  ImportacaoProfissional_visualizar_exportar: 'Importação de Profissionais - Visualizar Pendencias, Exportar Pendencias',
  SolicitacaoFerias_gerencial_detalhes_solicitacao: 'Painel de Solicitação de Férias (Gerencial) - Detalhes da Solicitação de Férias',
  Produtividade_emitir_relatorio: 'Produtividade - Emitir Relatório',
  Produtividade_visualizar_relatorio_cancelado: 'Produtividade - Visualizar Relatórios Cancelados',
  RelatorioAfastamento_gerencial_visualizar_exportar: 'Relatório de Afastamento (Gerencial) - Visualizar / Exportar Relatórios',
  ChefeImediato: 'CHEFEIMEDIATO',
  ImportacaoFichaFinanceira_adicionar_carga: 'Importação de Ficha Financeira - Adicionar Carga',
  ImportacaoFichaFinanceira_visualizar_exportar: 'Importação de Ficha Financeira - Visualizar Pendencias, Exportar Pendencias',
  FichaFinanceira_exportar: 'Acompanhamento de Ficha Financeira dos Servidores - Exportar',
  FichaFinanceira_detalhar: 'Acompanhamento de Ficha Financeira dos Servidores - Detalhar'
});

export const PERMISSOES_SEAD = Object.freeze({
  Cargo_adicionar_editar: 'Cargo - Adicionar, Editar',
  Cargo_ativar_inativar: 'Cargo - Ativar, Inativar',
  Cargo_excluir: 'Cargo - Excluir',
  Cargo_visualizar: 'Cargo - Visualizar',
  CargoComissionado_adicionar_editar: 'Cargo Comissionado - Adicionar, Editar',
  CargoComissionado_ativar_inativar: 'Cargo Comissionado - Ativar, Inativar',
  CargoComissionado_excluir: 'Cargo Comissionado - Excluir',
  CargoComissionado_visualizar: 'Cargo Comissionado - Visualizar',
  ClassificacaoFuncional_adicionar_editar: 'Classificação Funcional - Adicionar, Editar',
  ClassificacaoFuncional_ativar_inativar: 'Classificação Funcional - Ativar, Inativar',
  ClassificacaoFuncional_excluir: 'Classificação Funcional - Excluir',
  ClassificacaoFuncional_visualizar: 'Classificação Funcional - Visualizar',
  FormaAdmissao_adicionar_editar: 'Forma de Admissão - Adicionar, Editar',
  FormaAdmissao_ativar_inativar: 'Forma de Admissão - Ativar, Inativar',
  FormaAdmissao_excluir: 'Forma de Admissão - Excluir',
  FormaAdmissao_visualizar: 'Forma de Admissão - Visualizar',
  FormacaoAcademica_adicionar_editar: 'Formação Acadêmica - Adicionar, Editar',
  FormacaoAcademica_ativar_inativar: 'Formação Acadêmica - Ativar, Inativar',
  FormacaoAcademica_excluir: 'Formação Acadêmica - Excluir',
  FormacaoAcademica_visualizar: 'Formação Acadêmica - Visualizar',
  TipoAfastamento_adicionar_editar: 'Configurar Tipo de Afastamento - Adicionar, Editar',
  TipoAfastamento_ativar_inativar: 'Configurar Tipo de Afastamento - Ativar, Inativar',
  TipoAfastamento_excluir: 'Configurar Tipo de Afastamento - Excluir',
  TipoAfastamento_visualizar: 'Configurar Tipo de Afastamento - Visualizar',
  GrauInstrucao_adicionar_editar: 'Grau de Instrução - Adicionar, Editar',
  GrauInstrucao_ativar_inativar: 'Grau de Instrução - Ativar, Inativar',
  GrauInstrucao_excluir: 'Grau de Instrução - Excluir',
  GrauInstrucao_visualizar: 'Grau de Instrução - Visualizar',
  ModalidadeEnsino_adicionar_editar: 'Modalidade de Ensino - Adicionar, Editar',
  ModalidadeEnsino_ativar_inativar: 'Modalidade de Ensino - Ativar, Inativar',
  ModalidadeEnsino_excluir: 'Modalidade de Ensino - Excluir',
  ModalidadeEnsino_visualizar: 'Modalidade de Ensino - Visualizar',
  MotivoSaida_adicionar_editar: 'Motivo da Saída - Adicionar, Editar',
  MotivoSaida_ativar_inativar: 'Motivo da Saída - Ativar, Inativar',
  MotivoSaida_excluir: 'Motivo da Saída - Excluir',
  MotivoSaida_visualizar: 'Motivo da Saída - Visualizar',
  RegimeContratacao_adicionar_editar: 'Regime de Contratação - Adicionar, Editar',
  RegimeContratacao_ativar_inativar: 'Regime de Contratação - Ativar, Inativar',
  RegimeContratacao_excluir: 'Regime de Contratação - Excluir',
  RegimeContratacao_visualizar: 'Regime de Contratação - Visualizar',
  SituacaoFuncional_adicionar_editar: 'Situação Funcional - Adicionar, Editar',
  SituacaoFuncional_ativar_inativar: 'Situação Funcional - Ativar, Inativar',
  SituacaoFuncional_excluir: 'Situação Funcional - Excluir',
  SituacaoFuncional_visualizar: 'Situação Funcional - Visualizar',
  TipoAposentadoria_adicionar_editar: 'Tipo de Aposentadoria - Adicionar, Editar',
  TipoAposentadoria_ativar_inativar: 'Tipo de Aposentadoria - Ativar, Inativar',
  TipoAposentadoria_excluir: 'Tipo de Aposentadoria - Excluir',
  TipoAposentadoria_visualizar: 'Tipo de Aposentadoria - Visualizar',
  TipoCargoComissionado_adicionar_editar: 'Tipo de Cargo Comissionado - Adicionar, Editar',
  TipoCargoComissionado_ativar_inativar: 'Tipo de Cargo Comissionado - Ativar, Inativar',
  TipoCargoComissionado_excluir: 'Tipo de Cargo Comissionado - Excluir',
  TipoCargoComissionado_visualizar: 'Tipo de Cargo Comissionado - Visualizar',
  ProfessoresContratacaoEmergencial_adicionar_editar: 'Professores Contratação Emergencial - Adicionar, Editar',
  ProfessoresContratacaoEmergencial_ativar_inativar: 'Professores Contratação Emergencial - Ativar, Inativar',
  ProfessoresContratacaoEmergencial_excluir: 'Professores Contratação Emergencial - Excluir',
  ProfessoresContratacaoEmergencial_visualizar: 'Professores Contratação Emergencial - Visualizar',
  funcao_adicionar_editar: 'Função - Adicionar, Editar',
  funcao_ativar_inativar: 'Função - Ativar, Inativar',
  funcao_excluir: 'Função - Excluir',
  funcao_visualizar: 'Função - Visualizar',
});

export const PERMISSOES_LOTACAO = Object.freeze({
  LotacaoProfissionalEspelho_listar_gre_escola_visualizar_espelho: 'Lotação de Profissionais - Listar GRE, Listar Escolas, Visualizar Escola (espelho da escola)',
  LotacaoProfissional_demais_profissionais: 'Lotação de Profissionais - Lotação de Demais profissionais',
  LotacaoProfissional_gestores: 'Lotação de Profissionais - Lotação de Gestores',
  LotacaoProfissional_corpo_diretivo: 'Lotação de Profissionais - Listar Corpo diretivo',
  LotacaoProfissional_visualizar_corpo_diretivo_lotado: 'Lotação de Profissionais - Visualizar corpo Diretivo lotado',
  LotacaoProfissional_adicionar_editar_corpo_diretivo: 'Lotação de Profissionais - Adicionar, Editar profissional no corpo diretivo',
  LotacaoProfissional_remover_corpo_diretivo: 'Lotação de Profissionais - Remover profissional no corpo diretivo',
  LotacaoProfissional_exportar_corpo_diretivo: 'Lotação de Profissionais - Exportar corpo diretivo',
  LotacaoProfissional_exportar_demais_profissionais: 'Lotação de Profissionais - Lotação de Demais Profissionais | Exportar Demais Profissionais',
  LotacaoProfissional_adicionar_editar_demais_profissionais: 'Lotação de Profissionais - Lotação de Demais Profissionais | Adicionar/Editar Profissional',
  LotacaoProfissional_visualizar_demais_profissionais_lotado: 'Lotação de Profissionais - Lotação de Demais Profissionais | Visualizar Demais Profissionais',
  LotacaoProfissional_remover_demais_profissionais: 'Lotação de Profissionais - Lotação de Demais Profissionais | Remover Profissional',
  LotacaoProfissional_listar_demais_profissionais: 'Lotação de Profissionais - Lotação de Demais Profissionais | Listar',
  LotacaoProfissional_professor: 'Lotação de Profissionais - Lotação de Professores',
  LotacaoProfissional_apoio_pedagogico: 'Lotação de Profissionais - Lotação em Apoio Pedagógico',
  LotacaoAEE_adicionar_editar: 'Lotação de Profissionais AEE - Adicionar, Editar',
  LotacaoAEE_ativar_inativar: 'Lotação de Profissionais AEE - Ativar/Inativar',
  LotacaoAEE_excluir_sala: 'Lotação de Profissionais AEE - Excluir salas',
  LotacaoAEE_listar_gre_escola_sala: 'Lotação de Profissionais AEE - Listar GREs, Listar Escolas, Listar Salas AEE',
  LotacaoAEE_visualizar: 'Lotação de Profissionais AEE - Visualizar',
  LotacaoAEC_adicionar_editar: 'Lotação de Profissionais em Atividade Educacional Complementar - Adicionar, Editar',
  LotacaoAEC_ativar_inativar: 'Lotação de Profissionais em Atividade Educacional Complementar - Ativar/Inativar',
  LotacaoAEC_excluir_sala: 'Lotação de Profissionais em Atividade Educacional Complementar - Excluir salas',
  LotacaoAEC_listar_gre_turma: 'Lotação de Profissionais em Atividade Educacional Complementar - Listar GREs, Listar Turmas AEC',
  LotacaoAEC_visualizar: 'Lotação de Profissionais em Atividade Educacional Complementar - Visualizar',
  Produtividade_Visualizar: 'Produtividade - Visualizar Relatórios Cancelados',
  Produtividade_emitir_relatorio: 'Produtividade - Emitir Relatório',
  //lotação professores 2025
  LotacaoProfissional_professor_novo: 'Lotação de Profissionais - Lotação de Professores (Escolarização) | Listar',
  LotacaoProfissionar_lotar_professor_escolarizacao_visualizar : 'Lotação de Profissionais - Lotação de Professores (Escolarização) | Visualizar Professores lotados em componente',
  LotacaoProfissionar_lotar_professor_escolarizacao_lotar_editar : 'Lotação de Profissionais - Lotação de Professores (Escolarização) | Lotar/Editar professores em componente',
  LotacaoProfissionar_lotar_professor_escolarizacao_remover : 'Lotação de Profissionais - Lotação de Professores (Escolarização) | Remover',
  LotacaoProfissionar_lotar_professor_escolarizacao_adicionar_especial : 'Lotação de Profissionais - Lotação de Professores (Escolarização) | Adicionar Professor (Lotação Especial)',

});

export const PERMISSOES_HIERARQUIA = Object.freeze({
  GerenciaExecutiva_adicionar_editar: 'Gerência Executiva - Adicionar, Editar',
  GerenciaExecutiva_ativar_inativar: 'Gerência Executiva - Ativar, Inativar',
  GerenciaExecutiva_visualizar: 'Gerência Executiva - Visualizar',
  GerenciaRegional_adicionar_editar: 'Gerência Regional - Adicionar, Editar',
  GerenciaRegional_ativar_inativar: 'Gerência Regional - Ativar, Inativar',
  GerenciaRegional_visualizar: 'Gerência Regional - Visualizar',
  Nucleo_adicionar_editar: 'Núcleo - Adicionar, Editar',
  Nucleo_ativar_inativar: 'Núcleo - Ativar, Inativar',
  Nucleo_visualizar: 'Núcleo - Visualizar',
  SecretariaExecutiva_adicionar_editar: 'Secretaria Executiva - Adicionar, Editar',
  SecretariaExecutiva_ativar_inativar: 'Secretaria Executiva - Ativar, Inativar',
  SecretariaExecutiva_visualizar: 'Secretaria Executiva - Visualizar',
  SecretariaGeral_adicionar_editar: 'Secretaria Geral - Adicionar, Editar',
  SecretariaGeral_ativar_inativar: 'Secretaria Geral - Ativar, Inativar',
  SecretariaGeral_visualizar: 'Secretaria Geral - Visualizar',
  SubGerencia_adicionar_editar: 'Subgerência - Adicionar, Editar',
  SubGerencia_ativar_inativar: 'Subgerência - Ativar, Inativar',
  SubGerencia_visualizar: 'Subgerência - Visualizar'
});

export const PERMISSOES_CONFIG_FICHA_FINANCEIRA = Object.freeze({
  CodigoRubrica_adicionar_editar: 'Código de Rubrica - Adicionar, Editar',
  CodigoRubrica_ativar_inativar: 'Código de Rubrica - Ativar, Inativar',
  CodigoRubrica_visualizar: 'Código de Rubrica - Visualizar',
  CodigoRubrica_excluir: 'Código de Rubrica - Excluir'
});

export class UtilPermissoes {
  static SetarPermissoes(perfilAcesso: any, storageService: StorageService, permissionsService: NgxPermissionsService, permissoes: string[]) {
    if (perfilAcesso.toUpperCase() === 'ADMINISTRADOR') {
      storageService.setInLocalStorage('permissaoUsuario', 'ADMIN');
      permissionsService.addPermission('ADMIN');
    } else if (perfilAcesso.toUpperCase() === 'SERVIDOR') {
      storageService.setInLocalStorage('permissaoUsuario', 'SERVIDOR');
      permissionsService.addPermission('SERVIDOR');
    } else if (perfilAcesso.toUpperCase() === 'CHEFEIMEDIATO') {
      storageService.setInLocalStorage('permissaoUsuario', 'CHEFEIMEDIATO');
      permissionsService.addPermission('CHEFEIMEDIATO');
    }
    if(permissoes.length > 0) {
      storageService.setInLocalStorage('permissoes', permissoes);
      permissionsService.addPermission(permissoes);
    }
  }

  static async validarPermissoes(permissoes: string[], permissoesRota: any, permissionsService: NgxPermissionsService, router: Router): Promise<boolean> {
    const permissaoUsuario = permissoesRota.find(perm => permissoes.some(p => perm.includes(p)));
    const temPermissao = await this.verificarPermissaoUsuario(permissaoUsuario, permissionsService);
    if (!temPermissao) {
      await SweetalertCustom.showAlertConfirm('Acesso negado','warning','Ok','Você não possui permissão para acessar esta funcionalidade.').then(() => {
        router.navigate(['/central-notificacoes']);
      });
      return false;
    }
    return true;
  }

  static async verificarPermissaoUsuario(tipo: string, permissionsService: NgxPermissionsService): Promise<boolean>{
    return await permissionsService.hasPermission(tipo);
  }

  static async verificarSeEAdmin(tipo: string, permissionsService: NgxPermissionsService): Promise<boolean>{
    return await this.verificarPermissaoUsuario(tipo, permissionsService);
  }

  static async verificarPermissaoListagem(permissoesRota: any, permissionsService: NgxPermissionsService): Promise<boolean> {
    for (const perm of permissoesRota) {
      if (await UtilPermissoes.verificarPermissaoUsuario(perm, permissionsService)){
        return true;
      }
    }
    return false;
  }
}
